.title-sitemap {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    color: rgb(19,58,123);
    text-decoration: none;
}

.info-sitemap {
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    color: black;
    text-decoration: none;
    margin-top: 5px;
}

.slogan-sitemap {
    font-size: 25px;
    font-weight: 500;
    color: rgb(19,58,123);
    text-decoration: none;
}

.grid-sitemap {
    padding: 0px 20px 0px 20px;
}

@media only screen and (max-width: 960px) {
    .grid-sitemap {
        padding-left: 20px;
    }
}