.contact {
    margin: 0%;
    min-height: fit-content;
    height: fit-content;
    background-image: linear-gradient(to right, rgb(235, 235, 235), white 15%);
    padding: 5px 40px 0px 40px;
}

.welcome-contact {
    font-size: 40px;
    font-weight: 400;
    color: rgb(19,58,123);
    margin-bottom: -10px;
}

.group-contact {
    font-size: 40px;
    font-weight: 400;
    color: rgb(238, 63, 51);
}

.slogan-contact {
    font-size: 20px;
    font-weight: 500;
    color: rgb(19,58,123);
}

.title-contact {
    font-size: 15px;
    font-weight: 600;
    color: rgb(19,58,123);
}

.info-contact {
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    color: black;
    text-decoration: none;
    margin-top: 5px;
}

.map {
    top: 0;
    left: 0;
    width: 40vw;
    height: 50vh;
    margin-bottom: 40px;
}

@media only screen and (max-width: 960px) {
    .welcome-contact {
        font-size: 35px;
        font-weight: 400;
        color: rgb(19,58,123);
        margin-bottom: 10px;
        text-align: center;
    }
    
    .group-contact {
        text-align: center;
        font-size: 40px;
        font-weight: 400;
        color: rgb(238, 63, 51);
        margin-bottom: 25px;
    }

    .map {
        width: 80vw;
        height: 50vh;
        margin-bottom: 20px;
    }
}
