.physicians {
    height: fit-content;
}

.sidebar-button {
    width: 100%;
    text-align: end;
    border: none;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    background-color: rgb(203,212,226);
    color: rgb(19,58,123);
    padding: 10px 10px 10px 0px;
}

.sidebar-button:hover {
    background-color: rgb(137,157,189);
    color: white;
    font-weight: 500;
}

.selected {
    background-color: white;
    color: rgb(19,58,123);
}

.photorow {
    overflow: hidden;
}

.photorow img {
    max-width: 100%;
}

.physicianTitle {
    color: rgb(19,58,123);
    font-size: 30px;
    font-weight: 200;
}

.portfolio{
    padding: 25px 50px 25px 50px;
}

.inforow .steve1 {
    float: right;
    margin-right: -100px;
    scale: 1.3;
    min-width: 100%;
    max-height: 100%;
}

.inforow .steve3 {
    max-width: 100%;
    min-height: 100%;
}

.inforow .dohee1 {
    float: right;
    margin-right: -130px;
    scale: 1.3;
    min-width: 100%;
    max-height: 100%;
}

.inforow .dohee3 {
    max-width: 100%;
    min-height: 100%;
}

@media only screen and (max-width: 992px) {
    .physicians-sidebar {
        display: none;
    }

    .physicianTitle{
        text-align: center;
    }

    .photorow {
        text-align: center;
    }
}