.services {
    height: fit-content;
}

.sidebar-title {
    color: rgb(19,58,123);
    font-weight: 500;
    background-color: white;
}

.sidebar-title:hover {
    background-color: white;
    font-weight: 500;
    color: rgb(19,58,123);
}

.sidebar-items {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    background-color: rgb(203,212,226);
    color: rgb(19,58,123);
}

.last {
    margin-bottom: 80px;
}

.selected {
    background-color: rgb(137,157,189);
    color: white;
}

.sidebar-items:hover {
    background-color: rgb(137,157,189);
    color: white;
    font-weight: 500;
}

.physicians-sidebar {
    height: fit-content;
}

.treatmentRedEmphises {
    color: red;
}

.treatmentBlueEmphises {
    font-size: 1.1em;
    color: rgb(19,58,123);
}